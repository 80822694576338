import React from "react"
import "./Quadrant.css"
import Intro from "./Intro"
import PTC from "./PTC"
import QuoteDiv from "./QuoteDiv"
import WhoRaceLeading from "./WhoRaceLeading"
import SheerQuadrantReport from "./SheerQuadrantReport"
import SheerProviderListing from "./SheerProviderListing"
// import Intro2 from "./Intro2"
export default function Quadrant(props) {
  return (
    <>
      <Intro />
      {/* <PTC />
      <QuoteDiv />
      <WhoRaceLeading />
      <SheerQuadrantReport />
      <SheerProviderListing /> */}
    </>
  )
}
