import React from "react"
import Quadrant from "../../components/AimBox/ConnectedCar/Quadrant"
import Layout from "../../components/Base/Layout"
import SEO from "../../components/Base/SEO"

export default function home() {
  return (
    <Layout>
      <SEO title={"Connected Car Security Solution Market"} />
      <Quadrant />
    </Layout>
  )
}
