import React from "react"
import connecteCar from "../../../assets/connected-car-security-system.png"
import calendar from "../../../assets/aimbox/calendar.jpg"
import shopping from "../../../assets/aimbox/shopping.jpg"
import ReportBG from "../../../assets/frontnew.png"
import AddToCart from "../addToCart"

import "./Quadrant.css"

export default function () {
  return (
    <section className="mt-5 mb-5 pb-2 section-content">
      <div className="row ">
       
          <div
           className="h1 offset-md-1 section-heading col-md-8 col-sm-12">
        
           Connected Car Security Solution Market- Sheer AIM Box™ Assessment 2021 – Autonomous Vehicle driving the future amid uncertainties
          
          </div>
              <div>
                {/* <img src={hills} class="mainimg"/> */}
                <img src={ReportBG} />
              </div>
      </div>
            <div className="row">
              {/* <hr class="hr"/> */}
                <div className="col-md-9 offset-md-1">
                    <div className="div-border">
                      <img src={calendar} class="calendar"/>              
                      <span class="span-icon">  Nov 2020</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                </div>
                   {/* <img src={manicon} class="manicon2"/> 
                      <span class="span-icon">by Ronak Doshi, Aaditya Jain, Supratim Nandi, Vigitesh Tewary, Abhishek Chauhan, Shrey Kalawatia</span> */}
            </div>
            <div className="row">
              <div class='col-md-9 offset-md-1'>      
                  <div class="by-it-now" style={{display: "flex", justifyContent: "space-between", padding: "10px"}}>
                    <div>
                      <img src={shopping} class="cart"/>
                    </div>
                    <AddToCart reportItem={{price: 2999, displayPrice: '$2,999', id: '1', title: 'Connected Car', month:'Nov', pages: 30}} />
                     {/* <div class='inline'><div class="ico-container"><img src={shopping} class="cart"/></div></div>
                     <div class='inline'><div class="price-div"><span class="price-lable">$2,999</span></div></div>
                  <div class='vline'><div> <button class="by-it-now-bytton" >BUY IT NOW</button></div></div> */}
              </div>
            </div>
          </div>  

              
      <div className="row">
        <div className="col-md-9 offset-md-1">
          The connected car security market is quietly gaining new 1 unprecedented amount of traction with service provider enjoying client confidence in the United Stated and Europe market. The emerging regions in Asia are expected to drive the future with slow adoption of autonomous vehicle. Although the pandemic Covid-19 has slowed down the growth but acceleration in digital service delivery among the vehicles continues.
          To support OEM (vehicle manufacturer) in their market implementation strategy, the service providers (connected car solutions) are adopting technological innovation by showcasing their capabilities and credentials.

          In this report, Sheer AIM Box provides the assessment of market implementation & growth strategy of 19 leading connected car security solution service providers with respect to their market adoption, product mix, business strategy and portfolio in the respective market. The service providers were positioned on Sheer Analytics & Insights proprietary Sheer AIM Box™ and categorized into Leaders, Tech Evangelist, Aspirants, and Game Changers.
          <br /><br />
        </div>
        
        <div className="col-md-6 offset-md-3">
          <img
            src={connecteCar}
            style={{ objectFit: "contain", width: "inherit" }}
          ></img>
        </div>
        <div className="col-md-9 offset-md-1">
          <h2><strong>Scope</strong></h2>
          <p>
            The connected car security solution market is quite fragmented with the presence of some major companies like Karamba Security, Harman, Altran, Argus, GuardKnox, Cisco, NVIDIA, Intertrust, NCC Group, C2A Security, NXP, Upstream, Secunet, Mocana, Regulus Cyber, Bosch Escrypt, Thales, Penta Security, and Dellfer.
            </p>
        </div>
          <div className="col-md-9 offset-md-1">
            <h2><strong> Contents </strong></h2>
            <p>
              <div style={{marginBottom: "10px"}}>The report focusses on:</div>
             <ul>
              <li>Assessment of solution providers in terms of market implementation and market growth strategy</li>
              <li>Impact of service providers in terms of High, Medium & Low</li>
              <li>Major market trends and their implications for key stakeholders</li>
              <li>Sheer Analytics and Insights’ Sheer AIM Box for Connected Car Solutions Market</li>
              <li>Profiles of solutions providers</li>
              </ul>
           </p>
          </div>
            <div className="col-md-9 offset-md-1">
              <h2><strong>Domain</strong></h2>
             <p>Automotive</p>
             <hr class="hr"/> 
            </div>
            <div className="col-md-9 offset-md-1"><strong>Page Count:</strong> 30</div> 
      </div>
            
    </section>

  )
}
