import React, { useState, useEffect } from "react"

import PaymentModal from "../Payments/PaymentModal"

export default function ({ reportItem }) {
  const [showPayPalButton, setShowPaypalButton] = useState(false)
  useEffect(() => {
    if (reportItem) {
      if (!showPayPalButton) setShowPaypalButton(true)
    }
  }, [reportItem]);

  function showPaypal() {
      // return <PaypalButton total={value} currency={"USD"} />
      return (
        <PaymentModal
          buttonLabel={"Buy Now"}
          total={reportItem.price}
          reportItem={reportItem}
          currency={"USD"}
        />
      )
  }

  return (
    <div style={{display: "flex"}}>
      <div
        className={`rounded-0 mt-2 mb-2 mr-3`}
        id="exampleFormControlSelect1"
        style={{padding: "10px 20px", borderRight: "1px solid rebeccapurple"}}
      >
        {reportItem.displayPrice}
      </div>
      {showPaypal()}
    </div>
  )
}
